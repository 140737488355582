<template>
    <div class="relative !z-20" v-motion-fade>
        <!-- <div :class="['absolute top-5 sm:top-18 left-[3.2rem] sm:-left-12 h-fit z-10', { '-left-5': route.params?.project, 'hidden': (route.path == '/team') || (route.params.project) }]"> -->
            <!-- <MyBreadcrumbs v-if="route.path != '/user' && route.params.type != 'user' && !route.path.includes('/wellspring') && !route.path.includes('/account')" /> -->
        <!-- </div> -->
        <!-- <Button @click="testfunc">Test</Button> -->
        <!-- <pre>{{ test }}</pre> -->
        <div class="bg-muted w-full h-full overflow-y-visible z-20 relative" ref="dropZoneRef">
            <slot />
            <ChatDialog v-if="user && route?.params?.project && activePanel" />
        </div>




        <!-- <BottomTray/> -->
    </div>
</template>

<script setup lang="ts">
import Toaster from "@/components/ui/toast/Toaster.vue";
import { Loader2 } from "lucide-vue-next";
import { useDropZone } from '@vueuse/core';

const dropZoneRef = ref<HTMLDivElement>()



const userStore = useUserStore();
const uiStore = useUiStore();
const projectStore = useProjectStore();
const dockStore = useDockStore();

const { selectedTeam, plan, teams } = storeToRefs(userStore);
const { setTeams, setPlan, setSelectedTeam, setUser } = userStore;
const { setSelectedProject } = projectStore;

const user = useSupabaseUser()


const { activePanel } = storeToRefs(dockStore);

const { menu } = storeToRefs(uiStore);
const { projects } = storeToRefs(projectStore);
const { setPlans, setProjectImages, toggleBottomSheet } = uiStore;



const route = useRoute();
const queryParams = route.query;

const headers = useRequestHeaders(["cookie"]);


let realtimeChannelGroups: RealtimeChannel;
let realtimeChannelUsers: RealtimeChannel;


const { data: fetchedPlans, error: fetchedPlansError, refresh: refreshPlans } = await useLazyAsyncData(
    'fetched-plans',
    async () => {
        const { data } = await useFetch("/api/plans/list", {
            method: "GET",
            headers,
        });
        return data;
    }
);

watch(fetchedPlans, (newPlans) => {
    // console.log('new plans', newPlans)
    if (newPlans) {
        // console.log('setting new plans', newPlans)
        setPlans(newPlans.value?.products)
    }
}, { immediate: true })

const client = useSupabaseClient();

// onUnmounted(() => {
//     if (realtimeChannelUsers) {
//         client.removeChannel(realtimeChannelUsers);
//     }
//     if (realtimeChannelGroups) {
//         client.removeChannel(realtimeChannelGroups);
//     }
// });

const test = ref(null)

const testfunc = async () => {
    const { data } = await useFetch('/api/test', { headers })
    test.value = data
}

function onOver(files: File[] | null) {
    if (route.params?.team && !route.params.project) {
        toggleBottomSheet(true)
    }
}

const { isOverDropZone } = useDropZone(dropZoneRef, {
    onOver,
    // specify the types of data to be received.
    // dataTypes: ['image/jpeg']
})



const getRealtimeChannel = (table: string, schema: string, callback: () => void) => {
    return client
        .channel(`${schema}:${table}`)
        .on(
            "postgres_changes",
            { event: "*", schema: schema, table: table },
            callback
        )
        .subscribe();
};


const { pending: pendingUser, data: fetchedUser, refresh: refreshFetchedUser } = await useFetch("/api/users/user");

watch(fetchedUser, (newUser: any) => {
    if (newUser?.id) {
        setUser(newUser)
    }
}, { immediate: true })


realtimeChannelGroups = getRealtimeChannel("groups", "public", async () => {
    console.log('detected new gruop')
    await refreshFetchedTeams()
});
realtimeChannelUsers = getRealtimeChannel("users", "auth", refreshFetchedUser);








const personalTeam = computed(() => {
    return {
        id: user.value?.id,
        metadata: {
            name: user.value?.email || 'Personal',
            type: "Personal",
            description: "Personal account",
        },
    };
});





watch(route, (newRoute) => {
    console.log(newRoute.params)
    if (teams && newRoute.params.team) {
        const routeTeam = teams.value?.find(t => t.id == newRoute.params.team)
        setSelectedTeam(routeTeam)
    }

    if (projects && newRoute.params.project) {
        const routeProject = projects.value?.find(t => t.id == newRoute.params.project)
        setSelectedProject(routeProject)
    }

    if (newRoute.params.type == 'user') {
        // console.log('route is a personal user one', personalTeam)
        setSelectedTeam(personalTeam.value)
    }

})




const { data: userPlan, error, pending: pendingUserPlan } = await useLazyFetch("/api/users/plan", {
    method: "POST",
    headers
})

watch(userPlan, async (newPlan) => {
    if (newPlan) {
        setPlan(newPlan)
    } else {
        await refreshNuxtData('userPlan')
    }
}, {immediate: true})


const { data: fetchedTeams, error: teamsError, refresh: refreshFetchedTeams } = await useLazyAsyncData('fetched-teams', async () => {
    return await client.from("groups").select("*").in("id", Object.keys(user?.value?.app_metadata?.groups ?? {}))
})

watch(fetchedTeams, async (newTeams: any) => {
    if (newTeams?.data?.length !== 0) {
        setTeams(newTeams?.data)
    }
}, { immediate: true })
















</script>

